.com-button---component {
	display: flex;
	justify-content: center;
	gap: 10pt;
	min-width: 35pt;
	width: auto;
	background-color: #f7f7f7;
	color: black;
	border-radius: 12pt;
	border: none;
	width: 100%;
	cursor: pointer !important;
	> i {
		display: block;
		width: auto;
		height: 35pt;
		line-height: 35pt;
		text-align: center;
		color: black;
	}
	> h4 {
		display: block;
		width: auto;
		height: 35pt;
		line-height: 35pt;
		font-size: 10pt;
	}
}