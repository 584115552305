@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

//#region 																							D E F A U L T S
	
	* {
		user-select: none !important;
		-webkit-user-drag: none !important;
		margin: 0;
		padding: 0;
		font-family: "Quicksand", serif;
		font-optical-sizing: auto;
		font-style: normal;
		box-sizing: border-box !important;
	}

	::-webkit-scrollbar,
	*::-webkit-scrollbar {
		-webkit-appearance: none !important;
		display: none !important;
		opacity: 0 !important;
		width: 0pt !important;
	}

	body {
		background: transparent;
		position: relative;
		width: 100vw;
		height: 100vh;
		margin: 0;
		padding: 0;
		justify-items: center;
		align-items: center;
	}

	.c-printed-font {
		font-family: "JetBrains Mono", serif;
		font-optical-sizing: auto;
		font-style: normal;
	}

//#endregion

//#region 																							C O N T A I N E R

	.ticket---container---outer {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		max-width: 118mm;
		min-height: 100vh;
		padding: 0;
		overflow: auto !important;
		margin: 0;
	}

	.ticket---container {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
	//	overflow: auto !important;
		padding: 12pt;
		margin: 0;
	}

	.ticket---container---bg {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		filter: blur(48pt);
		transform: scale(1.5);
	}

//#endregion

//#region 																							U P P E R

	.ticket---container---upper {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 12pt;
		height: auto;
		padding: 24pt 12pt;
		mix-blend-mode: exclusion;
		* {
			color: white;
			text-align: center;
		}
		> img {
			height: 30pt;
			display: flex;
			justify-content: center;
			flex-direction: column;
			mix-blend-mode: exclusion;
		}
		> h4 {
			font-size: 12pt;
			font-weight: normal;
		}
	}

//#endregion

//#region 																							U S E R   D A T A S

	.ticket---container---user-data {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 12pt 12pt 5pt 12pt;
		width: 100%;
		background-color: #F4F4F4;
		border-radius: 12pt;
		gap: 5pt;
		> p {
			display: block;
			width: 100%;
			line-height: 1;
			text-align: center;
			&:nth-child(1) {
				font-size: 20pt;
				text-transform: uppercase;
			}
			&:nth-child(2) {
				font-size: 8pt;
				letter-spacing: 1pt;
				font-weight: bold;
				text-transform: uppercase;
				opacity: 0.5;
			}
		}
	}

//#endregion

//#region 																							C O D E

	.ticket---container---code {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		gap: 12pt;
		background-color: #f7f7f7;
		border-radius: 12pt;
		padding: 24pt;
		> img {
			display: block;
			height: 180pt;
			width: 100%;
			object-fit: contain;
			padding: 12pt;
		}
		> h6 {
			display: block;
			width: 100%;
			opacity: 0.5;
			word-break: break-all !important;
			text-align: center;
			text-transform: uppercase;
			font-size: 8pt;
			letter-spacing: 3pt;
		}
	}

	.ticket---container---lower {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		padding: 12pt;
		gap: 12pt;
		background-color: white;
		border-radius: 12pt;
	}

//#endregion

//#region 																							C O P Y R I G H T

.ticket---container---copyright {
	text-align: center;
	padding: 12pt;
	gap: 6pt;
	mix-blend-mode: exclusion;
	color: white;
	font-size: 10pt;
	> * {
		color: white;
		font-size: 10pt;
	}
	> a {
		font-weight: bold;
	}
}

//#endregion